import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Card from "../components/card/Card";
import { Form } from "../components/form/Form";
import { SubmitButton } from "../components/button/CustomButton";
import { InputText } from "../components/form/Input";
import { HTTPStatusResponse } from "../config/global";
import { APP_API_URL } from "../config/api";
import { useMutate } from "../hooks/useMutations";
import "../assets/styles/tombola.css";
import { ColumnInterface } from "../components/table/typings";
import moment from "moment";
import Table from "../components/table/Table";
import { useState } from "react";
import { Select2 } from "../components/form/Select";
import {useQueries} from "../hooks/useQueries";


export default function TombolaPage() {
  /**
   * Hooks
   */
  const [results, setResults] = useState([]);
  const [animateBall, setAnimateBall] = useState(false);

  /**
   * Form
   */
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /**
   * Mutation
   */
  const { mutateAsync, isLoading } = useMutate(
    APP_API_URL + `tombola`,
    "post"
  );
  const { data: lots } = useQueries(APP_API_URL + `lots?paginate=0`, [
    "lots",
  ]);

  /**
   * Function
   */
  const handleSubmitForm = (values: any) => {
    setResults([]);
    setAnimateBall(true);
    mutateAsync({
      number_code : values.number_code,
      lot_id: values.lot_id.id
    }).then((response: any) => {
      if (response && response.data.status === HTTPStatusResponse.OK) {
        //setTimeout(() => {
        toast.success("Tirage éffectué !");
        setResults(response.data.data);
        setAnimateBall(false);
        //}, 3000);
      }
    });
  };

  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "Code gagnant",
      key: "code",
      dataIndex: "code",
    },
    {
      title: "Nom du client",
      key: "client",
      dataIndex: "client",
    },
    {
      title: "Numéro de téléphone",
      key: "phone",
      dataIndex: "phone",
    },
    {
      title: "Lot",
      key: "lot",
      dataIndex: "lot",
    },
    {
      title: "Date",
      key: "created_at",
      render: (element: any) => (
        <>{moment(element.date).format("YYYY-MM-DD HH:mm:ss")}</>
      ),
    },
  ];

  /**
   * UI
   */

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <Card title={"Tombola"}>
          <div className="mx-auto px-4 border border-gray-200 rounded-full w-[400px] h-[400px] border-b-[100px] border-t-[#fff] border-t-[25px] relative flex items-center">
            <div
              className={`rounded-full h-[400px] p-10 relative w-full ${
                animateBall ? "animate" : ""
              }`}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value: number) => (
                <span key={value} className="loader-tombola">
                  {" "}
                  <span>{value}</span>
                </span>
              ))}
            </div>
          </div>
        </Card>
        <Form loading={isLoading} onSubmit={handleSubmit(handleSubmitForm)}>
          <Card
            title="Lancer la tombola"
            extras={[
              <SubmitButton
                value="Démarrer"
                loading={isLoading}
                key="submit"
              />,
            ]}
          >
            <div className={"mb-10"}>
              <Select2
                  placeholder="Selectionner le lot"
                  control={control}
                  name={"lot_id"}
                  error={errors?.lot_id}
                  register={register}
                  textKeyItem={"name"}
                  valueKeyItem={"id"}
                  items={lots?.data.data.data}
                  label="Lot"
              />
            </div>
            <div className={"mb-10"}>
              <InputText
                  placeholder={"Entrer le nombre de code à générer"}
                  label={"Code à générer"}
                  register={register}
                  error={errors?.number_code}
                  name={"number_code"}
              />
            </div>
            <Table columns={columns} loading={false} datas={results} />
          </Card>
        </Form>
      </div>
    </>
  );
}
