import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AddButton } from "../components/button/CustomButton";
import CardTable from "../components/card/CardTable";
import { InputText, TextArea } from "../components/form/Input";
import ModalForm from "../components/modal/ModalForm";
import { FormActionType, HTTPStatusResponse } from "../config/global";
import { ColumnInterface } from "../components/table/typings";
import { useQueries } from "../hooks/useQueries";
import { APP_API_URL } from "../config/api";
import { useMutateWithInvalidateQueries } from "../hooks/useMutations";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
import moment from "moment";

export default function CodePage() {
    /**
     * Hooks
     * */
    const {
        baseApiUrl, title, queryKey,
        titleForm, setTitleForm,
        openModal, setOpenModal,
        pagination, setPagination
    } = useHeaderFunctionPage({ baseApiUrl: 'code', title: 'Code' });

    /**
     * Form
     */
    const {
        register, reset,  handleSubmit, formState: {
            errors
        }
    } = useForm();

    /**
     * Query
     */
    const {
        data: datas,
        isLoading
    } = useQueries(APP_API_URL + `${baseApiUrl}s?page=${pagination.page}&perPage=${pagination.perPage}`, queryKey);

    /**
     * Mutation
     */
    const {
        mutateAsync: storeData,
        isLoading: storeDataIsLoading
    } = useMutateWithInvalidateQueries(APP_API_URL + `${baseApiUrl}-store`, 'post', queryKey)


    /**
     * Function
     */
    const handleSubmitAddForm = (values: any) => {
        storeData(values).then((response: any) => {
            if (response && response.data.status === HTTPStatusResponse.OK) {
                reset();
                toast.success('Code généré !');
                setOpenModal(false);
            }
        });
    };

    const handleCancelForm = () => {
        reset();
        setOpenModal(false);
    };

    const handleAddElement = () => {
        setTitleForm("Génerer des codes");
        setOpenModal(true);
        reset();
    }
    /**
     * Columns Table
     */
    const columns: ColumnInterface[] = [
        {
            title: "Numéro de code",
            key: "num_code",
            dataIndex: "num_code"
        },
        {
            title: "Code gagnant",
            key: "win_code",
            dataIndex: "win_code"
        },
        {
            title: "Date",
            key: "created_at",
            render: (element:any) => <>{moment(element.date).format("YYYY-MM-DD HH:mm:ss")}</>
        }
    ];

    /**
     * UI
     */
    return (
        <>
            <CardTable extras={[<AddButton key={"add"} onClick={handleAddElement} />]} columns={columns} title={title}
                loading={isLoading} datas={datas?.data.data}
                pagination={pagination} setPagination={setPagination}
                tableClassName="text-center" />
            <ModalForm title={titleForm} isLoading={storeDataIsLoading}
                onClose={handleCancelForm}
                onConfirm={handleSubmit(handleSubmitAddForm)}
                open={openModal}>
                <div className={"grid grid-cols-1 gap-4"}>
                    <div>
                        <InputText placeholder={"Entrer le nombre de code à générer"} label={"Code à générer"} register={register} error={errors?.number_code}
                            name={"number_code"} />
                    </div>
                </div>
            </ModalForm>
        </>
    );
}
