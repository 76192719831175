import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AddButton } from "../components/button/CustomButton";
import CardTable from "../components/card/CardTable";
import { InputText, TextArea } from "../components/form/Input";
import ModalForm from "../components/modal/ModalForm";
import { FormActionType, HTTPStatusResponse } from "../config/global";
import { ColumnInterface } from "../components/table/typings";
import { useQueries } from "../hooks/useQueries";
import { APP_API_URL } from "../config/api";
import { useMutateWithInvalidateQueries } from "../hooks/useMutations";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
import moment from "moment";

export default function ClientStepPage() {
    /**
     * Hooks
     * */
    const {
        baseApiUrl, title, queryKey,
        actionForm, setActionForm,
        titleForm, setTitleForm,
        currentElement, setCurrentElement,
        openModal, setOpenModal,
        pagination, setPagination
    } = useHeaderFunctionPage({ baseApiUrl: 'client-step', title: 'Client-Step' });

    /**
     * Form
     */
    const {
        register, reset,  handleSubmit, formState: {
            errors
        }
    } = useForm();

    /**
     * Query
     */
    const {
        data: datas,
        isLoading
    } = useQueries(APP_API_URL + `client-step?page=${pagination.page}&perPage=${pagination.perPage}`, queryKey);

    /**
     * Mutation
     */
    const {
        mutateAsync: storeData,
        isLoading: storeDataIsLoading
    } = useMutateWithInvalidateQueries(APP_API_URL + `${baseApiUrl}-store`, 'post', queryKey)

    const {
        mutateAsync: updateData,
        isLoading: updateIsLoading
    } = useMutateWithInvalidateQueries(APP_API_URL + `${baseApiUrl}/${currentElement}`, 'put', queryKey)

    /**
     * Function
     */
    const handleSubmitAddForm = (values: any) => {
        storeData({...values, typeSMSId: values.typeSMSId.id, languageId: values.languageId.id  }).then((response: any) => {
            if (response && response.data.status === HTTPStatusResponse.OK) {
                reset();
                toast.success('Model de SMS ajoutée !');
                setOpenModal(false);
            }
        });
    };

    const handleSubmitEditForm = (values: any) => {
        updateData({...values, typeSMSId: values.typeSMSId.id, languageId: values.languageId.id  }).then((response: any) => {
            if (response && response.data.status === HTTPStatusResponse.OK) {
                reset();
                toast.success('Model de SMS modifiée');
                setOpenModal(false);
                setCurrentElement(0);
            }
        });
    };

    const handleCancelForm = () => {
        reset();
        setOpenModal(false);
    };

    const handleAddElement = () => {
        setTitleForm("Ajouter un model de SMS");
        setActionForm(FormActionType.ADD);
        setOpenModal(true);
        reset();
    }
    /**
     * Columns Table
     */
    const columns: ColumnInterface[] = [
       
        {
            title: "Téléphone",
            key: "phone_number",
            dataIndex: "phone_number"
        },
        {
            title: "Date",
            key: "created_at",
            render: (element:any) => <>{moment(element.date).format("YYYY-MM-DD HH:mm:ss")}</>
        }
    ];

    /**
     * UI
     */
    return (
        <>
            <CardTable extras={[]} columns={columns} title={title}
                loading={isLoading} datas={datas?.data.data}
                pagination={pagination} setPagination={setPagination}
                tableClassName="text-center" />
            <ModalForm title={titleForm} isLoading={actionForm === FormActionType.ADD ? storeDataIsLoading : updateIsLoading}
                onClose={handleCancelForm}
                onConfirm={handleSubmit(actionForm === FormActionType.ADD ? handleSubmitAddForm : handleSubmitEditForm)}
                open={openModal}>
                <div className={"grid grid-cols-1 gap-4"}>
                    <div>
                        <InputText placeholder={"Entrer le titre"} label={"Titre"} register={register} error={errors?.title}
                            name={"title"} />
                    </div>
                    <div>
                        <TextArea placeholder={"Entrer le message"} label={"Message"} register={register} error={errors?.message}
                            name={"message"} />
                    </div>
                </div>
            </ModalForm>
        </>
    );
}
