import { Link, useLocation } from "react-router-dom";
import BoxIcons from "../icons/BoxIcons";
import { MENUS, MenuInterface } from "../../config/menus";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CheckRole from "../../utils/CheckRole";

function TopNav({ visible }: { visible: boolean }) {
  const location = useLocation();
  const pathname = location.pathname.replace("/", "");
  const { t } = useTranslation();
  const ItemMenu = ({ menu }: { menu: MenuInterface }) => {
    const [show, setShow] = useState(false);
    if (menu.subMenus) {
      return (
        <li
          className={`nav-item dropdown md:flex items-center ${
            pathname.includes(menu.pathname) ? "active" : ""
          }`}
        >
          <button
            className={`hover:cursor-pointer nav-link dropdown-toggle arrow-none w-full`}
            onClick={() => setShow(!show)}
          >
            <BoxIcons
              icon={menu.icon}
              solid={menu.iconSolid}
              className={"mr-2"}
            />
            <span>{t(menu.title)}</span>
            <div className="arrow-down"></div>
          </button>
          <div className={`dropdown-menu md:top-12 ${show ? "show" : ""}`}>
            {menu.subMenus.map((subMenu: MenuInterface, index: number) => {
              return (
                CheckRole(subMenu.roles) && (
                  <Link
                    to={subMenu.link}
                    key={"sub-menu" + index}
                    className="dropdown-item"
                  >
                    <BoxIcons
                      icon={subMenu.icon}
                      solid={menu.iconSolid}
                      className={"mr-2"}
                    />
                    <span>{t(subMenu.title)}</span>
                  </Link>
                )
              );
            })}
          </div>
        </li>
      );
    } else {
      return (
        <li
          className={`${
            pathname.includes(menu.pathname) ? "active" : ""
          } nav-item`}
        >
          <Link
            to={menu.link}
            className={`${
              pathname.includes(menu.pathname) ? "active" : ""
            } nav-link dropdown-toggle arrow-none`}
          >
            <BoxIcons
              icon={menu.icon}
              solid={menu.iconSolid}
              className={"mr-2"}
            />
            <span>{t(menu.title)}</span>
          </Link>
        </li>
      );
    }
  };

  return (
    <div className={`topnav ${!visible ? "hidden" : ""}`}>
      <nav className="navbar navbar-light navbar-expand-lg topnav-menu active">
        <div className={`navbar-collapse `} id="topnav-menu-content">
          <ul className="navbar-nav sm:flex">
            {MENUS.map((menu: MenuInterface, index: number) => {
              return (
                CheckRole(menu.roles) && (
                  <ItemMenu menu={menu} key={"menu-" + index} />
                )
              );
            })}
          </ul>
        </div>
      </nav>
    </div>
  );
}
export default TopNav;
