import { UserRoles } from "./global"

export interface MenuInterface {
    icon: string,
    iconSolid?: boolean,
    title: string,
    link: string,
    pathname: string,
    roles?: UserRoles[],
    subMenus?: MenuInterface[],
}
export const MENUS: MenuInterface[] = [
    {
        icon: "hourglass",
        iconSolid: false,
        title: "Tombola",
        link: "/tombola",
        pathname: "tombola",
        roles: []
    },
    {
        icon: "user",
        iconSolid: false,
        title: "Client",
        link: "/client",
        pathname: "client",
        roles: []
    },
    {
        icon: "code-alt",
        iconSolid: false,
        title: "Code",
        link: "/code",
        pathname: "code",
        roles: []
    },
    {
        icon: "user",
        iconSolid: false,
        title: "Client-step",
        link: "/client-step",
        pathname: "client-step",
        roles: []
    },
    {
        icon: "money",
        iconSolid: false,
        title: "Lot",
        link: "/lot",
        pathname: "lot",
        roles: []
    },
    {
        icon: "code-alt",
        iconSolid: false,
        title: "Game",
        link: "/game",
        pathname: "game",
        roles: []
    },

]
